.intro{
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 15% 0 15%;
}

ul{
    float:left;
}
.emailform{
    display:flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

input{
    width: 30vw;
    height: 30px;
}
textarea{
    width:30vw;
    height:200px;
}
#submitbtn{
    width:30vw;
    height:45px;
    background-color: #32669E;
    color: floralwhite;
    margin: 30px 0 30px 0;
}
label{
    font-weight: bold;
}

@media only screen and (max-width: 768px) {
    input{
        width: 80vw;
       
    }
    textarea{
        width:80vw;
        
    }
    #submitbtn{
        width:80vw;
        
    }

}
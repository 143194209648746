.slides{
    display:flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0 20px 0;
    flex-direction: column;
}
.slides img{
    width:80vw;
    height: 400px;
    background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.slidetext{
    display:flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0 20px 0;
    font-style: italic;
    font-weight: bolder;
}

@media only screen and (max-width: 768px) {
    .slides img{
        width:96vw;
        height:auto;

}
}
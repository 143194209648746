.manu{
    display:flex;
    align-items: center;
    justify-content: center;
    padding:20px 0 20px 0;
}
.manu img{
    width:80vw;
}


@media only screen and (max-width: 768px) {
    .manu img{
        width:96vw;
 

}
}
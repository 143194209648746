.infosnippet{
    display: flex;
    align-items: flex-start;
    text-align: center;
    
}

.infosnippet a{
    border-radius: 15px;
    text-decoration: none;
    color: rgb(180, 31, 31);
    font-weight: bolder;
    font-size: 1.5em;
}

.infocontainer{
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    padding:10px 70px 10px 70px;
    align-content: flex-start;
    flex-wrap: wrap-reverse;
}

@media only screen and (max-width: 768px) {
    .infocontainer div{
        justify-content: center;
        align-items: center;
    }
}

